$(document).ready(function(){
  // don't need anything here because we were able to move it all into the turbolinks:load event
});

$(document).on('turbolinks:load', function() {

  $('form#new_donation').on('keydown', function(e) {
    if (e.which === 13 && e.target.id !== "donation_temp_postal_code" && e.target.id !== 'postal-code-lookup' && !e.target.classList.contains('select-postal-code') && !e.target.classList.contains('btn-next') && !e.target.classList.contains('btn-previous') && !e.target.classList.contains('btn-cancel')) {
      e.preventDefault();
    }
  })

  $('button#postal-code-lookup').on('click', function(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    $.ajax({
      url: '/canada_postal_addresses',
      data: {postal_code: $('#donation_temp_postal_code').val()},
      dataType: 'json',
      method: 'GET'
    }).done(function(data) {
      // Special behaviour if the postal code is one of a handful of Cape Breton postal codes
      let capeBreton = ["B0C", "B0E", "B0H", "B1A", "B1B", "B1C", "B1E", "B1G", "B1H", "B1J", "B1K ", "B1L", "B1M", "B1N", "B1P", "B1R", "B1S", "B1T", "B1V", "B1W", "B1X", "B1Y", "B2A", "B2C", "B2E", "B2J", "B9A"]

        // clear out any existing data in the table
      $('table.postal-code-search-results tbody').empty()
      for (i = 0; i < data.length; i++) {
        let first3 = data[i].postal_code.substring(0,3)
        let province = data[i].province
        if (capeBreton.includes(first3)) {
          province = 'CB'
        }

        $('table.postal-code-search-results tbody').append("<tr><td>"+data[i].postal_code+"</td><td>"+data[i].city+"</td><td>"+province+"</td><td>"+data[i].street_name+"</td><td>"+data[i].street_type+"</td><td><a data-postal-code='"+data[i].postal_code+"' data-city='"+data[i].city+"' data-province='"+province+"' data-street-name='"+data[i].street_name+"' data-street-type='"+data[i].street_type+"' href='#' class='select-postal-code'>Select</a></tr>")
      }
      $('a.select-postal-code').on('click', function(evt) {
        evt.preventDefault()
        evt.stopPropagation()

        $('table.postal-code-search-results tr').removeClass('table-warning')
        $(this).parents('tr').addClass('table-warning')

        $('#donation_postalcode_check_passed').val('filled').change().blur()

        $('input#donation_postal_code')
          .val(this.dataset["postalCode"])
          .change()
          .blur()
          .addClass('is-valid')

        $('input#donation_city')
          .val(this.dataset["city"])
          .change()
          .blur()
          .addClass('is-valid')

        $('select#donation_province')
          .val(this.dataset["province"])
          .change()
          .blur()
          .addClass('is-valid')

        $('input#donation_street_name')
          .val(this.dataset["streetName"] + ' ' + this.dataset["streetType"])

        let other_entries = $('table.postal-code-search-results tr:not(.table-warning)')
        other_entries.hide()
        if (other_entries.length > 1) {
          document.querySelector('.postal-code-lookup-header').scrollIntoView({
            behavior: 'smooth'
          });
        }

        validatePage(this)
      })
      $('.postal-code-search-results').removeClass('hidden')
      $('.postal-code-search-instructions').removeClass('hidden')  
    })
  })

  $('#donation_skip_postalcode').on('change', function () {
    if ($(this)[0].checked) {
      $('#donation_postalcode_check_passed').val('filled').change().blur()
    } else {
      $('#donation_postalcode_check_passed').val('').change().blur()
    }
  })

  $('#donation_submission_confirmation').on('change', function () {
    if ($(this)[0].checked) {
      $('#donation_submission_confirmation_check').val('filled').change().blur()
      $('.btn.btn-done').removeClass('disabled').removeAttr('disabled')
      $('input#donation_validate_only').val('false')
      $('form#new_donation').attr('data-remote', false)
    } else {
      $('#donation_submission_confirmation_check').val('').change().blur()
      $('.btn.btn-done').addClass('disabled').attr('disabled', 'disabled')
      $('input#donation_validate_only').val('true')
      $('form#new_donation').attr('data-remote', true)
    }
  })

  $('form.edit_donation, form.new_donation').on('submit', function(evt) {
    // When someone presses enter on a form and hasn't blurred the field, the upcase never happens. This code prevents that behaviour.    
    if (document && document.activeElement) document.activeElement.blur()
  })

  $('body.donations-wizard form#new_donation').on('ajax:success', function(evt) {
    [data, status, xhr] = evt.detail

    $('.validation-errors').empty() // clear any existing validation errors

    document.querySelector('.review-section').scrollIntoView({
      behavior: 'smooth'
    });
  }).on('ajax:error', function(evt) {
    [data, status, xhr] = evt.detail

    $('.validation-errors').empty() // clear any existing validation errors

    document.querySelector('.review-section').scrollIntoView({
      behavior: 'smooth'
    });
    
    if (status === "Internal Server Error") {
      $('.server-response').html("<p>There has been a catastrophic server error. Show this to a Team Captain, and raise the alarm right away.</p><pre>"+data+"</pre>")
      return
    }

    // This is most likely a 400 error, so let's show the bad info.
    $('.server-response').html("<p>There are validation errors. See below.</p>")
    for (let [key, values] of Object.entries(data)) {
      $('.donation_'+key+'-errors').html(values.join('; '))
    }
  })

  $('a.pre-fill').on('click', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    switch ($(this).data('province')) {
      case 'CB':
        $('#donation_address').val('C/O CTV SYDNEY').removeClass('is-invalid').addClass('is-valid')
        $('#donation_address2').val('1283 GEORGE ST').removeClass('is-invalid').addClass('is-valid')
        $('#donation_city').val('SYDNEY, NS').removeClass('is-invalid').addClass('is-valid')
        $('#donation_province').val('CB').removeClass('is-invalid').addClass('is-valid')
        $('#donation_po_box').val('')
        $('#donation_postal_code').val('B1P 1N7').removeClass('is-invalid').addClass('is-valid')
        break
      case 'NB':
        $('#donation_address').val('C/O CTV MONCTON').removeClass('is-invalid').addClass('is-valid')
        $('#donation_address2').val('191 HALIFAX ST.').removeClass('is-invalid').addClass('is-valid')
        $('#donation_city').val('MONCTON').removeClass('is-invalid').addClass('is-valid')
        $('#donation_province').val('NB').removeClass('is-invalid').addClass('is-valid')
        $('#donation_po_box').val('')
        $('#donation_postal_code').val('E1C 9R7').removeClass('is-invalid').addClass('is-valid')
        break
      case 'PE':
        $('#donation_address').val('C/O CTV MONCTON').removeClass('is-invalid').addClass('is-valid')
        $('#donation_address2').val('191 HALIFAX ST.').removeClass('is-invalid').addClass('is-valid')
        $('#donation_city').val('MONCTON, NB').removeClass('is-invalid').addClass('is-valid')
        $('#donation_province').val('PE').removeClass('is-invalid').addClass('is-valid')
        $('#donation_po_box').val('')
        $('#donation_postal_code').val('E1C 9R7').removeClass('is-invalid').addClass('is-valid')
        break
      case 'NS':
        $('#donation_address').val('C/O CTV HALIFAX').removeClass('is-invalid').addClass('is-valid')
        $('#donation_address2').val('2885 ROBIE ST').removeClass('is-invalid').addClass('is-valid')
        $('#donation_city').val('HALIFAX').removeClass('is-invalid').addClass('is-valid')
        $('#donation_province').val('NS').removeClass('is-invalid').addClass('is-valid')
        $('#donation_po_box').val('1653')
        $('#donation_postal_code').val('B3J 2Z4').removeClass('is-invalid').addClass('is-valid')
        break
    }
  })
  
  $('input#donation_amount').on('change', function() {
    let amount = $(this).val()
    if ($('.user-role').html() === "agent" && amount > 200) {
      if (!confirm("The amount entered is over $200. Is this correct?")) {
        $(this).val('')
      }
    }
    if (amount < 0) {
      alert("Amounts less than $0 are not valid.")
      $(this).val('')
    }
  })

  $('select#donation_donation_method').on('change', function() {
    let val = $(this).val()
    if (val === "Mail in") {
      $('.mail-in-addresses').removeClass('hidden')
    } else {
      $(".mail-in-addresses").addClass('hidden')
    }
  })

  // on the new page
  $('select#donation_redirect_donation').on('change', function() {
    let val = $(this).val()
    if (val === "true") {
      $('.outside-maritime-form').removeClass('hidden')
      $('.redirection-confirmation').removeClass('hidden')
      $('input#donation_redirect_community').addClass('required')
      $('select#donation_redirect_province').addClass('required')
    } else {
      $('.outside-maritime-form').addClass('hidden')
      $('.redirection-confirmation').addClass('hidden')
      $('input#donation_redirect_community').val('').removeClass('required').change()
      $('select#donation_redirect_province').val('').removeClass('required').change()
    }
  })

  // on the non-wizard pages
  $('form.edit_donation input#donation_redirect_donation, form.new_donation input#donation_redirect_donation').on('change', function() {
    let val = $(this).prop('checked')
    if (val) {
      $('input#donation_redirect_community').prop('disabled', false)
      $('select#donation_redirect_province').prop('disabled', false)
    } else {
      $('input#donation_redirect_community').prop('disabled', true)
      $('select#donation_redirect_province').prop('disabled', true)
      $('input#donation_redirect_community').val('').removeClass('required').change()
      $('select#donation_redirect_province').val('').removeClass('required').change()
    }
  })

  $('input#donation_house_no, input#donation_street_name').on('change', function() {
    $('input#donation_address')
      .val(
        $('input#donation_house_no')
          .val()
          .toUpperCase() + ' ' + $('input#donation_street_name')
          .val()
          .toUpperCase()
        )
      .change()
      .blur()
  })

  $('input#donation_first, input#donation_last').on('change, blur', function() {
    $('input#donation_prime_display_1')
      .val(
        $('input#donation_first')
          .val()
          .toUpperCase() + ' ' + $('input#donation_last')
          .val()
          .toUpperCase()
        )
      .change()
      .blur()
  })

  $('input#donation_city, select#donation_province').on('change', function() {
    let donation_province = $('select#donation_province')
      .val()
      .toUpperCase()
    if (donation_province === "CB") {
      donation_province = "NS"
    }
    $('input#donation_prime_display_2')
      .val(
        $('input#donation_city')
        .val()
        .toUpperCase() + ', ' + donation_province
      )
      .change()
      .blur()

    $('.prime_display_2').html($('input#donation_prime_display_2').val())
  })

  $('select#donation_province').on('change', function() {
    let donation_province = $('select#donation_province')
      .val()
      .toUpperCase()
    let isAgent = false
    if ($('.user-role').html() === "agent") {
      isAgent = true
    }
    if (["CB", "NB", "NS", "PE"].includes(donation_province)) {
      $('select#donation_redirect_donation').val("false").change().blur()
      if (isAgent) {
        $('#donation_donation_method option').prop('disabled', false)
      }
      switch (donation_province) {
        case "CB":
          $('.mail-in-addresses .cape-breton').removeClass('hidden')
          $('.mail-in-addresses .new-brunswick').addClass('hidden')
          $('.mail-in-addresses .nova-scotia').addClass('hidden')
          $('.mail-in-addresses .pei').addClass('hidden')
          break
        case "NB":
          $('.mail-in-addresses .cape-breton').addClass('hidden')
          $('.mail-in-addresses .new-brunswick').removeClass('hidden')
          $('.mail-in-addresses .nova-scotia').addClass('hidden')
          $('.mail-in-addresses .pei').addClass('hidden')
          break
        case "NS":
          $('.mail-in-addresses .cape-breton').addClass('hidden')
          $('.mail-in-addresses .new-brunswick').addClass('hidden')
          $('.mail-in-addresses .nova-scotia').removeClass('hidden')
          $('.mail-in-addresses .pei').addClass('hidden')
          break
        case "PE":
          $('.mail-in-addresses .cape-breton').addClass('hidden')
          $('.mail-in-addresses .new-brunswick').addClass('hidden')
          $('.mail-in-addresses .nova-scotia').addClass('hidden')
          $('.mail-in-addresses .pei').removeClass('hidden')
          break
        }
    } else {
      $('select#donation_redirect_donation').val("true").change().blur()
      if (isAgent) {
        $('#donation_donation_method option[value="Credit Union in CB"]').prop('disabled', true)
        $('#donation_donation_method option[value="Royal Bank"]').prop('disabled', true)
      }
      $('.mail-in-addresses .cape-breton').removeClass('hidden')
      $('.mail-in-addresses .new-brunswick').removeClass('hidden')
      $('.mail-in-addresses .nova-scotia').removeClass('hidden')
      $('.mail-in-addresses .pei').removeClass('hidden')
    }
  })

  $('input#donation_prime_display_1').on('change', function() {
    displayNameChanged('prime', '1')
  })

  $('input#donation_prime_display_2').on('change', function() {
    displayNameChanged('prime', '2')
  })

  $('input#donation_secondary_display_1').on('change', function() {
    displayNameChanged('secondary', '1')
  })

  $('input#donation_secondary_display_2').on('change', function() {
    displayNameChanged('secondary', '2')
  })

  $('select#donation_chyron').on('change', function() {
    let val = $(this).val()
    if (val === "true") {
      $('.prime-display').removeClass('hidden')
      $('select#donation_prime_display_names_correct').removeClass('is-valid')
      $('select#donation_prime_display_names_correct').addClass('is-invalid')
      $('select#donation_secondary_display_names_correct').addClass('is-valid')
      $('select#donation_secondary_display_names_correct').removeClass('is-invalid')
      $('.displayname-confirmation').removeClass('hidden')
    } else {
      $('#donation_chyron2').val('false').change()
      $('select#donation_prime_display_names_correct')
        .addClass('is-valid')
        .removeClass('is-invalid')
        .val('')
        .change()
      $('select#donation_secondary_display_names_correct')
        .addClass('is-valid')
        .removeClass('is-invalid')
        .val('')
        .change()
      $('.displayname-confirmation').addClass('hidden')
      $('.prime-display').addClass('hidden')
      $('.secondary-display').addClass('hidden')

      // clear out the contents of the fields
      $('input#donation_prime_display_1').val('').change()
      $('input#donation_prime_display_2').val('').change()
      $('input#donation_secondary_display_1').val('').change()
      $('input#donation_secondary_display_2').val('').change()
    }
    validatePage(this)
  })

  $('select#donation_chyron2').on('change', function() {
    let val = $(this).val()
    if (val === "true") {
      $('.secondary-display').addClass('hidden')
      $('.override-secondary-display').removeClass('hidden')
      $('select#donation_secondary_display_names_correct').removeClass('is-valid')
      $('select#donation_secondary_display_names_correct').addClass('is-invalid')
      $('.secondary-displayname-confirmation').removeClass('hidden')
    } else {
      $('.secondary-display').addClass('hidden')
      $('.override-secondary-display').addClass('hidden')
      $('select#donation_secondary_display_names_correct').addClass('is-valid')
      $('select#donation_secondary_display_names_correct').removeClass('is-invalid')
      $('.secondary-displayname-confirmation').addClass('hidden')

      // clear out the contents of the fields
      $('input#donation_secondary_display_1').val('').change()
      $('input#donation_secondary_display_2').val('').change()
    }
    validatePage(this)
  })

  // on the non-wizard pages
  $('form.edit_donation input#donation_chyron, form.new_donation input#donation_chyron').on('change', function() {
    let val = $(this).prop('checked')
    if (val) {
      $('input#donation_prime_display_1').prop('disabled', false)
      $('input#donation_prime_display_2').prop('disabled', false)
      $('input#donation_chyron2').prop('disabled', false)
    } else {
      $('input#donation_prime_display_1').prop('disabled', true)
      $('input#donation_prime_display_2').prop('disabled', true)
      $('input#donation_secondary_display_1').prop('disabled', true)
      $('input#donation_secondary_display_2').prop('disabled', true)
      $('input#donation_chyron2').prop('checked', false)
      $('input#donation_chyron2').prop('disabled', true)
      $('input#donation_prime_display_1').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_prime_display_2').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_secondary_display_1').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_secondary_display_2').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
    }
  })

  $('form.edit_donation input#donation_chyron2, form.new_donation input#donation_chyron2').on('change', function() {
    if ($(this).prop('checked')) {
      $('input#donation_secondary_display_1').prop('disabled', false)
      $('input#donation_secondary_display_2').prop('disabled', false)
    } else {
      $('input#donation_secondary_display_1').prop('disabled', true)
      $('input#donation_secondary_display_2').prop('disabled', true)
      $('input#donation_secondary_display_1').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_secondary_display_2').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
    }
  })

  $('select#donation_prime_display_names_correct').on('change', function() {
    displayNamesCorrect(this, 'prime')
    validatePage(this)
  })

  $('select#donation_secondary_display_names_correct').on('change', function() {
    displayNamesCorrect(this, 'secondary')
    validatePage(this)
  })

  $('#update-prime-display-names').on('click', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    updateDisplayNames('prime')
  })

  $('#update-secondary-display-names').on('click', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    updateDisplayNames('secondary')
  })

  // Change all input.uppercase to actually be uppercase, rather than simply appearing uppercase due to the text-transform: uppercase CSS instruction
  $('input.uppercase').on('blur', function() {
    $(this).val($(this).val().toUpperCase())
  })

  $('input, select').on('blur', function() {
    // run validations
    let val = $(this).val()
    let valid = false

    if ($(this).hasClass('required')) {
      if (val === "") {
        valid = false
      } else {
        valid = true
      }
    } else {
      valid = null
    }

    // postal code field happens to always be required, so in the case of an empty val(), this will be set to false
    if (/postal_code/.exec($(this).attr('id'))) {
      let postal_code_re = /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/
      let pc_match = postal_code_re.exec(val)
      if (pc_match === null) {
        valid = false
      } else {
        valid = true
      }
    }

    // phone number is always required
    if (/phone/.exec($(this).attr('id'))) {
      let phone_re = /^(\d\d\d)-?(\d\d\d)-?(\d\d\d\d)$/
      let phone_match = phone_re.exec(val)
      if (phone_match === null) {
        valid = false
      } else {
        valid = true
        $(this).val(phone_match[1] + '-' + phone_match[2] + '-' + phone_match[3])
      }
    }

    // non-required fields are not subject to verification
    if (valid !== null) {
      if (valid) {
        $(this).addClass('is-valid')
        $(this).removeClass('is-invalid')
      } else {
        $(this).addClass('is-invalid')
        $(this).removeClass('is-valid')
      }
    }

    validatePage(this)

    // update the data on the review page
    let id = this.id
    let value = this.value
    $('.'+id+'-confirm').html(value)
  })

  $('input#donation_temp_postal_code').on('change', function() {
    $('input#donation_postal_code').val($('input#donation_temp_postal_code').val())
    $('input#donation_postal_code').addClass('is-valid')
  })

  $('.btn-next').on('click', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    $('a[href="'+$(this).data('href')+'"]').trigger('click')
  })

  $('.btn-previous').on('click', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    $('a[href="'+$(this).data('href')+'"]').trigger('click')
  })

  $('.donation-state-control').on('change', function() {
    if ($(this).val() === "Paid") {
      $('.paid-detail').show()
    } else {
      $('.paid-detail').hide()
    }
  })

  $('#donation_receipt_requested').on('change', function() {
    if ($(this)[0].checked) {
      $('#donation_receipt_date').attr('disabled', false)
    } else {
      $('#donation_receipt_date').attr('disabled', true)
    }
  })

  // input masks
  $('#donation_temp_postal_code').inputmask('A9A 9A9', {placeholder: ' '})
  $('#donation_postal_code').inputmask('A9A 9A9', {placeholder: ' '})
  $('#donation_phone').inputmask('999-999-9999', {placeholder: ' '})
  $('#donation_po_box').inputmask('PO BOX *{1,}', {placeholder: ' ', clearIncomplete: true})
  $('#billing-cc-number').inputmask('9999 9999 9999 9999', {placeholder: ' '})
  $('#billing-cc-exp').inputmask('99/99', {placeholder: ' '})
  $('#billing-cvv').inputmask('999', {placeholder: ' '})

  // As a receipt user, I can't choose state="Fail"
  $('form.edit_donation.user_role_receipt_user')
      .find('option[value=Fail]').attr('disabled', 'disabled').end()
      .find('option[value=Refunded]').attr('disabled', 'disabled')

  $('#donation_greeting_name').blur(function() {
    $('#donation_first').val($(this).val()).blur()
  })

  $('#donation_greeting_last_name').blur(function() {
    $('#donation_last').val($(this).val()).blur()
  })

  $('#donation_donation_method').change(function() {
    if ($(this).val() === 'Credit Card') {
      $('#credit_card_address_confirmation').show()
    } else {
      $('#credit_card_address_confirmation').hide()
    }
  })

  $('button.btn-review').on('click', function() {
    $('.review-section').removeClass('hidden')
    $(this).text("Update review")
  })

  // Custom back button location on credit card page
  var back_button = $('.back_button_goes_here');
  if (back_button.length > 0) {
    current_title = document.title;
    current_location = location.pathname;
    history.pushState({page: back_button.data('title')}, back_button.data('title'), back_button.data('url')); // add the edit address page to our history
    history.pushState({page: current_title}, current_title, current_location); // then add the current page back again
  }

  // page doesn't reload properly when we go back so we have to use this kludge
  window.addEventListener("popstate", function() {
    if (window.history.state.page !== 'undefined') { // if the state has a page attribute it's because it's one we added
      window.history.go(0); // reload the page
    }
  });

})

// This used to check whether a particular page of the wizard was valid
// Since we switched to a single-page design, the only thing we use this for is to make sure at least one address field is filled in
function validatePage(elem) {

  // Check address fields
  if (elem.id === 'donation_house_no' || elem.id === 'donation_street_name' || elem.id === 'donation_po_box') {
    let house_no = $('#donation_house_no')
    let street_name = $('#donation_street_name')
    let po_box = $('#donation_po_box')

    if (
      (house_no.val() === "" && street_name.val() === "" && po_box.val() === "") ||
      ((house_no.val() === "" || street_name.val() === "") && po_box.val() === "")
    ) {
      house_no.addClass('required').addClass('is-invalid')
      street_name.addClass('required').addClass('is-invalid')
      po_box.addClass('required').addClass('is-invalid')
    } else {
      house_no.removeClass('required').removeClass('is-invalid').addClass('is-valid')
      street_name.removeClass('required').removeClass('is-invalid').addClass('is-valid')
      po_box.removeClass('required').removeClass('is-invalid').addClass('is-valid')
    }
  }
}

function displayNamesCorrect(_this, which) {
  let val = $(_this).val()
  if (val === "false") {
    $('.'+which+'-display').addClass('hidden')
    $('.override-'+which+'-display').removeClass('hidden')
    $(_this).removeClass('is-valid')
    $(_this).addClass('is-invalid')
  } else {
    $('.'+which+'-display').removeClass('hidden')
    $('.override-'+which+'-display').addClass('hidden')
  }
}

function updateDisplayNames(which) {
  $('input#donation_'+which+'_display_1').val($('input#donation_'+which+'_display_1').val().toUpperCase())
  $('input#donation_'+which+'_display_2').val($('input#donation_'+which+'_display_2').val().toUpperCase())
  $('.'+which+'_display_1').html($('input#donation_'+which+'_display_1').val())
  $('.'+which+'_display_2').html($('input#donation_'+which+'_display_2').val())
  $('.'+which+'-display').removeClass('hidden')
  $('.override-'+which+'-display').addClass('hidden')
  $('select#donation_'+which+'_display_names_correct').val('').removeClass('is-valid').addClass('is-invalid').change()
}

function displayNameChanged(which, line) {
  $('input#donation_'+which+'_display_'+line)
    .val(
      $('input#donation_'+which+'_display_'+line)
        .val()
        .substring(0,32)
      )
    // .change() DO NOT FIRE CHANGE AGAIN!
    .blur() // updates the -confirm element on the review page

  $('.'+which+'_display_'+line).html($('input#donation_'+which+'_display_'+line).val())
}
